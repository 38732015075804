var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { Container, Content, Image, Title, Description, ContentContainer, ContentDiv, PayButton, BottomContainer, Column, ScrollContainer, } from './styles';
import { Building, PlusIconBlue } from 'src/assets/Images';
import { DUE_DATE, PAY, SEE_MORE, EXPIRED, PAYED, } from 'src/Utils/constants';
import { dateExpired } from 'src/Utils';
import { Line } from 'src/Components/AlertsCard/styles';
import DownloadContainer from './Components/DownloadContainer';
var Card = function (_a) {
    var data = _a.data, title = _a.title, _b = _a.isCommonExpenses, isCommonExpenses = _b === void 0 ? false : _b, setSelectedReceipt = _a.setSelectedReceipt, setIsOpenModal = _a.setIsOpenModal, linkTo = _a.linkTo;
    var renderRightContainer = function (currency, amount, id, default_bank, payed) {
        if (payed) {
            return (React.createElement(PayButton, { className: "payed" },
                React.createElement(Description, { className: "payed" }, PAYED)));
        }
        else {
            return (React.createElement(PayButton, { onClick: function () {
                    setSelectedReceipt === null || setSelectedReceipt === void 0 ? void 0 : setSelectedReceipt(id);
                    setIsOpenModal === null || setIsOpenModal === void 0 ? void 0 : setIsOpenModal(function (prev) { return !prev; });
                } },
                React.createElement(Description, { className: "button-title" }, PAY),
                default_bank ? (React.createElement(Column, null,
                    React.createElement(Description, { className: "button-title" }, "Banco: "),
                    React.createElement(Description, { className: "amount" }, default_bank))) : (React.createElement(Description, { className: "amount" }, currency + ' ' + amount))));
        }
    };
    var renderItem = function (data) {
        var expirationDateString = data.dateRange.split('/').reverse().join('-');
        var expired = dateExpired(expirationDateString) && !data.payed;
        return (React.createElement(React.Fragment, null,
            React.createElement(Image, { className: "building", src: Building }),
            React.createElement(ContentContainer, null,
                React.createElement(ContentDiv, null,
                    React.createElement(Description, { className: "bold padding-left" }, isCommonExpenses ? data.building : data.building.name)),
                React.createElement(ContentDiv, null,
                    React.createElement(Description, { className: "date" },
                        isCommonExpenses && (expired ? EXPIRED : DUE_DATE) + ": ",
                        ' ',
                        data.dateRange))),
            isCommonExpenses
                ? !expired &&
                    data.currency &&
                    data.amount &&
                    renderRightContainer(data.currency, data.amount, data.id, data.default_bank, data.payed)
                : React.createElement(DownloadContainer, __assign({}, data))));
    };
    return (React.createElement(Container, null,
        React.createElement(Title, null, title),
        React.createElement(Line, null),
        React.createElement(ScrollContainer, null, data &&
            data.map(function (item, index) {
                return React.createElement(Content, { className: (isCommonExpenses && !dateExpired(item.dateRange)) || item.pdf
                        ? ''
                        : 'no-button', key: index + "-" + (item.id ? item.id : item.pdfName) }, renderItem(item));
            })),
        React.createElement(BottomContainer, { onClick: linkTo },
            React.createElement("img", { src: PlusIconBlue }),
            SEE_MORE)));
};
export default Card;

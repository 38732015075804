import React from 'react';
import { HeroSection, OurServices, ContactSection, AppSection, Footer, } from './Views';
import { Div } from './styles';
import { Header } from './Layouts';
function Root() {
    return (React.createElement(React.Fragment, null,
        React.createElement(Header, null),
        React.createElement(Div, null,
            React.createElement(HeroSection, null),
            React.createElement(OurServices, null),
            React.createElement(AppSection, null),
            React.createElement(ContactSection, { isContactPage: false })),
        React.createElement(Footer, null)));
}
export default Root;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme, M } from '../../Utils';
export var Container = styled.section(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  min-height: 150px;\n  width: 100%;\n  padding: 30px 0px;\n  align-items: center;\n  flex-direction: column;\n  justify-content: center;\n  background-color: ", ";\n  @media (max-width: ", "px) {\n    padding: 40px 0px;\n    height: 100%;\n  }\n"], ["\n  display: flex;\n  min-height: 150px;\n  width: 100%;\n  padding: 30px 0px;\n  align-items: center;\n  flex-direction: column;\n  justify-content: center;\n  background-color: ", ";\n  @media (max-width: ", "px) {\n    padding: 40px 0px;\n    height: 100%;\n  }\n"])), theme.palette.primary[75], M);
export var SocialMediaContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  width: 30%;\n  max-width: 300px;\n  padding-top: 20px;\n  align-items: center;\n  justify-content: center;\n  background-color: ", ";\n  @media (max-width: ", "px) {\n    width: 140px;\n  }\n"], ["\n  display: flex;\n  width: 30%;\n  max-width: 300px;\n  padding-top: 20px;\n  align-items: center;\n  justify-content: center;\n  background-color: ", ";\n  @media (max-width: ", "px) {\n    width: 140px;\n  }\n"])), theme.palette.primary[75], M);
export var ButtonsContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  width: 70%;\n  max-width: 1100px;\n  padding: 20px 0px;\n  align-items: center;\n  justify-content: space-between;\n  background-color: ", ";\n  @media (max-width: ", "px) {\n    flex-direction: column;\n  }\n"], ["\n  display: flex;\n  width: 70%;\n  max-width: 1100px;\n  padding: 20px 0px;\n  align-items: center;\n  justify-content: space-between;\n  background-color: ", ";\n  @media (max-width: ", "px) {\n    flex-direction: column;\n  }\n"])), theme.palette.primary[75], M);
export var Button = styled.button(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  cursor: pointer;\n  background-color: ", ";\n  color: ", ";\n  border: none;\n  transition: 0.3s ease;\n  width: max-content;\n  font-weight: 500;\n  font-size: 18px;\n  font-family: ", ";\n  &:focus {\n    border: none;\n    outline: none;\n    text-decoration: underline;\n  }\n  @media (max-width: ", "px) {\n    font-size: 14px;\n    margin-top: 35px;\n  }\n"], ["\n  cursor: pointer;\n  background-color: ", ";\n  color: ", ";\n  border: none;\n  transition: 0.3s ease;\n  width: max-content;\n  font-weight: 500;\n  font-size: 18px;\n  font-family: ", ";\n  &:focus {\n    border: none;\n    outline: none;\n    text-decoration: underline;\n  }\n  @media (max-width: ", "px) {\n    font-size: 14px;\n    margin-top: 35px;\n  }\n"])), theme.palette.primary[75], theme.palette.white[100], theme.typography.normal.raleway, M);
export var ImageContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  width: 100%;\n  justify-content: center;\n  display: flex;\n  cursor: pointer;\n  align-items: center;\n  padding: 10px 0px;\n"], ["\n  width: 100%;\n  justify-content: center;\n  display: flex;\n  cursor: pointer;\n  align-items: center;\n  padding: 10px 0px;\n"])));
export var Image = styled.img(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 30px;\n  max-height: 100px;\n  @media (max-width: ", "px) {\n    width: 20px;\n  }\n"], ["\n  width: 30px;\n  max-height: 100px;\n  @media (max-width: ", "px) {\n    width: 20px;\n  }\n"])), M);
export var Div = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  color: ", ";\n  font-size: 16px;\n  font-style: normal;\n  font-weight: normal;\n  margin-top: 30px;\n  @media (max-width: ", "px) {\n    display: none;\n  }\n"], ["\n  color: ", ";\n  font-size: 16px;\n  font-style: normal;\n  font-weight: normal;\n  margin-top: 30px;\n  @media (max-width: ", "px) {\n    display: none;\n  }\n"])), theme.palette.white[100], M);
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;

var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from '@emotion/styled';
import { theme, L, M } from 'src/Utils';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  height: 100%;\n  justify-content: space-between;\n  align-items: center;\n  position: relative;\n  @media (max-width: ", "px) {\n    flex-direction: column;\n    background-repeat: no-repeat;\n    background-size: contain;\n  }\n"], ["\n  display: flex;\n  width: 100%;\n  height: 100%;\n  justify-content: space-between;\n  align-items: center;\n  position: relative;\n  @media (max-width: ", "px) {\n    flex-direction: column;\n    background-repeat: no-repeat;\n    background-size: contain;\n  }\n"])), M);
export var ImageContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  width: 35%;\n  height: 100%;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  margin-left: 10px;\n  margin-top: 50px;\n  padding: 40px 0px;\n  @media (max-width: ", "px) {\n    margin-top: 0px;\n    width: 50%;\n  }\n"], ["\n  display: flex;\n  width: 35%;\n  height: 100%;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  margin-left: 10px;\n  margin-top: 50px;\n  padding: 40px 0px;\n  @media (max-width: ", "px) {\n    margin-top: 0px;\n    width: 50%;\n  }\n"])), M);
export var Img = styled.img(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  width: 95%;\n  max-height: 450px;\n  margin-bottom: 20px;\n  &.absolute {\n    position: absolute;\n    height: 100%;\n  }\n  &.circle {\n    left: -3%;\n    top: -48%;\n    width: 60px;\n  }\n"], ["\n  width: 95%;\n  max-height: 450px;\n  margin-bottom: 20px;\n  &.absolute {\n    position: absolute;\n    height: 100%;\n  }\n  &.circle {\n    left: -3%;\n    top: -48%;\n    width: 60px;\n  }\n"])));
export var ContentContainer = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  width: 60%;\n  height: 100%;\n  justify-content: center;\n  align-items: center;\n  position: absolute;\n  right: 0;\n  @media (max-width: ", "px) {\n    position: relative;\n    width: 80%;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  width: 60%;\n  height: 100%;\n  justify-content: center;\n  align-items: center;\n  position: absolute;\n  right: 0;\n  @media (max-width: ", "px) {\n    position: relative;\n    width: 80%;\n  }\n"])), M);
export var DescriptionContainer = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  height: 100%;\n  justify-content: center;\n  align-items: center;\n  background-repeat: no-repeat;\n  background-size: cover;\n  @media (max-width: ", "px) {\n    background-color: ", ";\n    border-radius: 7px;\n  }\n"], ["\n  display: flex;\n  width: 100%;\n  height: 100%;\n  justify-content: center;\n  align-items: center;\n  background-repeat: no-repeat;\n  background-size: cover;\n  @media (max-width: ", "px) {\n    background-color: ", ";\n    border-radius: 7px;\n  }\n"])), M, theme.palette.grey[15]);
export var TitleContainer = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  width: 100%;\n  height: 35%;\n  font-size: 36px;\n  align-items: center;\n  font-family: ", ";\n  justify-content: center;\n  width: 90%;\n  @media (max-width: ", "px) {\n    font-size: 18px;\n  }\n  @media (max-width: ", "px) {\n    margin-top: 25px;\n    align-items: center;\n    max-width: 170px;\n    text-align: center;\n  }\n"], ["\n  display: flex;\n  width: 100%;\n  height: 35%;\n  font-size: 36px;\n  align-items: center;\n  font-family: ", ";\n  justify-content: center;\n  width: 90%;\n  @media (max-width: ", "px) {\n    font-size: 18px;\n  }\n  @media (max-width: ", "px) {\n    margin-top: 25px;\n    align-items: center;\n    max-width: 170px;\n    text-align: center;\n  }\n"])), theme.typography.bold.ralewayBold, L, M);
export var Description = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  font-family: ", ";\n  font-size: 16px;\n  white-space: pre-line;\n  @media (max-width: ", "px) {\n    font-size: 14px;\n  }\n"], ["\n  font-family: ", ";\n  font-size: 16px;\n  white-space: pre-line;\n  @media (max-width: ", "px) {\n    font-size: 14px;\n  }\n"])), theme.typography.OpenSans, L);
export var Subtitle = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  font-family: ", ";\n  font-size: 22px;\n  margin-bottom: 20px;\n  min-height: 52px;\n  @media (max-width: ", "px) {\n    font-size: 16px;\n  }\n  @media (max-width: ", "px) {\n    margin: 10px 0px;\n    min-height: 0px;\n  }\n"], ["\n  font-family: ", ";\n  font-size: 22px;\n  margin-bottom: 20px;\n  min-height: 52px;\n  @media (max-width: ", "px) {\n    font-size: 16px;\n  }\n  @media (max-width: ", "px) {\n    margin: 10px 0px;\n    min-height: 0px;\n  }\n"])), theme.typography.bold.ralewayBold, L, M);
export var DivContainer = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  width: 80%;\n  margin-left: 20px;\n  justify-content: space-between;\n  height: 70%;\n  align-items: baseline;\n  @media (max-width: ", "px) {\n    flex-direction: column;\n    margin-left: 0px;\n    height: 90%;\n  }\n"], ["\n  display: flex;\n  width: 80%;\n  margin-left: 20px;\n  justify-content: space-between;\n  height: 70%;\n  align-items: baseline;\n  @media (max-width: ", "px) {\n    flex-direction: column;\n    margin-left: 0px;\n    height: 90%;\n  }\n"])), M);
export var Div = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  width: 45%;\n  @media (max-width: ", "px) {\n    width: 100%;\n  }\n"], ["\n  display: flex;\n  flex-direction: column;\n  width: 45%;\n  @media (max-width: ", "px) {\n    width: 100%;\n  }\n"])), M);
export var ButtonsContainer = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  margin-top: 20px;\n  display: flex;\n  width: 100%;\n  justify-content: space-between;\n"], ["\n  margin-top: 20px;\n  display: flex;\n  width: 100%;\n  justify-content: space-between;\n"])));
export var ImageButton = styled.img(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  width: 45%;\n  height: 40px;\n  margin-bottom: 20px;\n"], ["\n  width: 45%;\n  height: 40px;\n  margin-bottom: 20px;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;

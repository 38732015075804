export var ABOUT_ORDERS = 'sobre los puntos a tratarse de la Asamblea del día ';
export var ABOUT_US = 'Sobre nosotros';
export var ABOUT_US_DESCRIPTION = 'Tenemos la nota característica de haber sido fundada en 1985 como una empresa familiar, convirtiendonos en la primera administración de edificios en Malvin. Los pilares fundamentales de nuestra gestión, que se traducen en seriedad, transparencia y confianza, marcan la tendencia de nuestra labor, experiencia y prestigio.';
export var ACCOUNT = 'Cuenta';
export var ACTIONS = 'Acciones';
export var ACCOUNT_TEXT = 'cuenta';
export var ACCEPT = 'Aceptar';
export var ACCEPT_TERMS = 'Acepto los';
export var ACTUAL_PASSWORD = 'Clave actual';
export var ADMINISTRATOR = 'Administrador';
export var ADMINISTRATOR_KEY = 'administrator';
export var ADMINS = 'Administradores';
export var ADMINS_TEXT = 'administrador';
export var ADMIN_DESCRIPTION = 'Somos líderes en Administración de Edificios. Contamos con un equipo altamente capacitado y con la experiencia necesaria para llevar adelante esta importante labor.';
export var ADDRESS = 'Dirección';
export var ADD_EXTRAS = 'Agregar Extras';
export var ADD_PROVIDER = 'Agregar proveedor';
export var ADD_FILE_ERROR = 'No se ha podido subir un archivo';
export var ADD_CLIENT_CODE = 'Agregar nuevo código';
export var ALERTS = 'Alertas';
export var ALERTS_API = 'getAlerts';
export var ALL = 'Todos';
export var ALL_ENG = 'All';
export var ALL_FIELDS_ARE_REQUIRED = '* Todos los campos son requeridos';
export var AMENITY = 'Espacio común';
export var AMENITIES_API = 'getAmenities';
export var AMENITY_KEY = 'amenity';
export var AMENITIES_NOT_AVAILABLE = '- No hay espacios disponibles -';
export var AMOUNT = 'Monto';
export var AMOUNT_TEXT = 'Importe';
export var AMOUNT_KEY = 'amount';
export var AMOUNT_PAID = 'Monto pagado';
export var APARTMENTS = 'Apartamentos';
export var APARTMENT = 'Apartamento';
export var APARTMENT_CONTACTS = 'Contactos de: Apartamento ';
export var APARTMENT_TEXT = 'apartamento';
export var APARTMENTS_TEXT = 'apartamentos';
export var APARTMENTS_QUANTITY = 'Cantidad de apartamentos';
export var APARTMENTS_SECOND_TEXT = 'apartments';
export var APARTMENT_KEY = 'apartment';
export var APARTMENT_ERROR = 'Ya existe un contacto con esta relación en el apartamento';
export var APARTMENT_DATA_KEY = 'apartmentData';
export var APARTMENT_NOT_FOUND = 'Apartamento no encontrado';
export var AVAILABILITY_API = 'getAvailability';
export var DOWNLOAD_APP_LINK_APPLE = 'https://apps.apple.com/app/apple-store/id6444747085';
export var DOWNLOAD_APP_LINK_ANDROID = 'https://play.google.com/store/apps/details?id=com.effectus.fascioli';
export var APT = 'Relación de apto';
export var APT_KEY = 'apto';
export var ASSEMBLY = 'Asamblea';
export var ASSEMBLIES_API = 'getAssemblyDocuments';
export var ASSEMBLIES_MINUTES = 'Actas de asambleas';
export var ASSEMBLIES_MINUTES_TEXT = 'actas_asamblea';
export var ASSEMBLIES_SUMMONS_TITLE = 'Citaciones a asambleas';
export var ASSEMBLIES_SUMMONS = 'Citación a asamblea';
export var ASSEMBLIES_SUMMONS_TEXT = 'citacion_asambleas';
export var ASSEMBLY_TYPE = 'Tipo de asamblea';
export var ASSEMBLY_TYPES = [
    'ordinary',
    'extraordinary',
    'urgent',
    'Intermediate 1/4 raise',
];
export var ASSEMBLY_KEY = 'type';
export var ASSOCIATED_CITATION = 'Citación Asociada';
export var ASSOCIATED_CONTACTS = 'Contactos asociados';
export var ADMINISTRATION = 'Administración';
export var ADD = 'Agregar';
export var ADD_RECEIPT = 'Agregar Recibo';
export var ADD_BUILDING = 'Agregar Edificio';
export var ADMINS_API = 'getAdminUsers';
export var AUTHORIZES = 'autoriza al Sr./Sra.';
export var ADDRESS_KEY = 'address';
export var AFTERNOON_HOURS = '13:15 - 17:00';
export var APARTMENTS_API = 'getApartments';
export var APP_MODAL = {
    message: 'Para visualizar los documentos con formato correcto, descarga la app.',
    successMessage: 'Descargar App',
    failMessage: 'Ahora no',
};
export var APPLY = 'Aplicar';
export var ATTORNEY_SIGNATURE = 'Firma apoderado';
export var BACK_ERROR = 'Hubo un error, intenta mas tarde';
export var BACK_ERROR_KEY = 'backError';
export var BATHROOM = 'Baño';
export var BASE_PRICE = 'Precio Base';
export var BEDROOM = 'Dormitorio';
export var BILL = 'Factura';
export var BILLS = 'Facturas';
export var BILLS_API = 'getBills';
export var BILL_TEXT = 'factura';
export var BUILDING_DATA_KEY = 'buildingData';
export var BILLING_PERIOD = 'Período de facturación';
export var BILLING_PERIOD_START = 'Inicio de período de facturación';
export var BILLING_PERIOD_END = 'Fin de período de facturación';
export var BUILDINGS = 'Edificios';
export var BUILDINGS_TEXT = 'edificios';
export var BUILDING = 'Edificio';
export var BUILDING_TEXT = 'edificio';
export var BUILDING_KEY = 'building';
export var BUILDINGS_KEY = 'buildings';
export var BUILDING_API_KEY = 'building_id';
export var BUILDING_API = 'getBuildingsList';
export var BUILDING_MENTIONED = 'del Edificio antes mencionado,';
export var BUSINESS_HOURS = 'Horario de atención';
export var BOTH = 'Ambos';
export var BOTH_KEY = 'both';
export var CALENDAR = 'Calendar';
export var CALENDAR_TEXT = 'calendar';
export var CALL = 'Convocatoria';
export var CANCEL = 'Cancelar';
export var CANCEL_RESERVATION = 'Cancelar reserva';
export var CANCEL_BOOKING_MESSAGE = '¿Seguro que desea cancelar la reserva?';
export var CELLPHONE = 'Celular';
export var CHANGE = 'Cambiar';
export var CHANGE_PASSWORD = 'Cambiar contraseña';
export var CLEAR_DATA = 'Vaciar datos';
export var CLEAR_DATA_MODAL = 'los datos del edificio';
export var CLEAR_FILTERS = 'Limpiar filtros';
export var CLOSE_WINDOW = 'Puede cerrar esta ventana';
export var CLOSED_DAYS = 'Semana de Carnaval y Turismo permanecemos cerrados';
export var CLIENT_API = 'getClient';
export var CLIENT_NUMBER = 'Nº de Cliente';
export var CLIENT_KEY = 'client';
export var CLIENT_CODE = 'Código del cliente';
export var CLIENT_CODE_KEY = 'client_code';
export var CITATION = 'citation';
export var COLLECTION_MANAGEMENT = 'Gestión de cobros';
export var SISTARBANC = 'Conciliaciones Sistarbanc';
export var CHARACTERISTICS = 'Características';
export var COMMON_EXPENSES = 'Gastos comunes';
export var COMMON_EXPENSES_API = 'getCommonExpenses';
export var COMMON_EXPENSES_DETAILS = 'Detalle Gastos Comunes';
export var COMMON_EXPENSES_RECEIPTS = 'Recibos Gastos Comunes';
export var COMMON_EXPENSES_RECEIPTS_TEXT = 'recibos_gastos_comunes';
export var CONCILIATED = 'Conciliado';
export var CONFIRM = 'Confirmar';
export var CONFIRM_PASSWORD_KEY = 'passwordConfirmation';
export var CONFIRM_NEW_PASSWORD = 'Confirmación clave nueva';
export var CONFIRM_NEW_PASSWORD_TEXT = 'Confirmar nueva contraseña';
export var CONTACT = 'Contacto';
export var CONTACTS = 'Contactos';
export var CONTACTS_API = 'getContactsInTable';
export var CONTACTS_TEXT = 'contactos';
export var CONTACT_TEXT = 'contacto';
export var CONTACT_KEY = 'contact';
export var COPYRIGHT = '© Copyright 2024 Fascioli Administraciones';
export var CREATE = 'Crear';
export var CREATED = 'Creado';
export var CREATED_KEY = 'created';
export var CREATE_ADMIN = 'Crear administrador';
export var CREATE_ACCOUNT = 'Crear cuenta';
export var CREATE_CONTACT = 'Crear contacto';
export var CREATE_SUMMON = 'Crear citación';
export var CREATE_MESSAGE_ERROR = 'No se ha podido crear el mensaje';
export var CREATE_MESSAGE_CONTACT_ERROR = 'Todos los contactos deben existir';
export var CREATE_RESERVATION = 'Crear reserva para cliente';
export var CREATE_RESERVATION_TITLE = 'Crear Reserva';
export var CREATION_DATE = 'Fecha de creación';
export var CURRENCY = 'Currency';
export var CURRENCY_TITLE = 'Moneda';
export var CURRENCY_KEY = 'currency';
export var CURRENT_PASSWORD_KEY = 'current_password';
export var DIRECTION_KEY = 'direction';
export var DIRECTION = 'Dirección';
export var DATE = 'Fecha';
export var DATE_FORMAT = 'dd/MM/yyyy';
export var DATE_FORMAT_MOMENT = 'DD/MM/YYYY';
export var LONG_DATE_FORMAT = 'D MMMM YYYY';
export var DATE_TEXT = 'date';
export var DATE_SET_KEY = 'set_date';
export var DATE_KEY = 'dateKey';
export var DATE_UPLOADED = 'Fecha en la que se subió';
export var DASHBOARD_TEXT = 'dashboard';
export var DELETE = 'Eliminar';
export var DELETE_ACCOUNT = 'Eliminar cuenta';
export var DELETE_FILE_ERROR = 'No se ha podido eliminar el documento';
export var DELETE_WARNING = 'Está seguro que desea eliminar ';
export var DESCRIPTION = 'Descripción';
export var DESCRIPTION_KEY = 'description';
export var DIRECTION_1 = 'Colombes 1399';
export var DIRECTION_2 = 'Montevideo';
export var DOCUMENTS = 'Documentos';
export var DOCUMENTS_API = 'getDocumentsList';
export var DOCUMENT = 'Documento';
export var DOCUMENTS_TEXT = 'documentos';
export var DOCUMENT_TEXT = 'documento';
export var DOCUMENT_KEY = 'document';
export var DOTS_TEXT = 'dots';
export var DPTO = 'Dpto';
export var DRAFT = 'Borrador';
export var TOWERS_TEXT = 'tower';
export var DOWNLOAD = 'Descargar';
export var DOWNLOAD_REPORT = 'Descargar informe';
export var DUE_DATE = 'Vencimiento';
export var EMAIL = 'Email';
export var NO_PROPERTIES = {
    title: 'No hay propiedades que coincidan con tu búsqueda.',
    subtitle: 'Modificá o eliminá tus filtros para encontrar más opciones.',
};
export var ENTER_NEW_PASSWORD = 'Ingresar nueva contraseña';
export var ENTER_NEW_EMAIL = 'Ingresar Email nuevo';
export var EDIT = 'Editar';
export var EDIT_RECEIPT = 'Editar recibo gastos comunes';
export var EDIT_RESERVATION = 'Editar Reserva';
export var EDIT_DETAILS = 'Editar datos';
export var EDIT_PERSONAL_DETAILS = 'Modificar datos personales';
export var EMAIL_KEY = 'email';
export var EMAIL_1 = 'recepcion@fascioli.com.uy';
export var EMAIL_2 = '';
export var EMPTY_SUMMONS = 'No hay citaciónes para mostrar';
export var EXPIRATION_DATE = 'Fecha de vencimiento';
export var EXPIRATION_DATE_TEXT = 'Fecha de Vto.';
export var EXPIRED = 'Venció';
export var EXPIRED_STATUS = 'Vencido';
export var EXTRAS = 'Extras';
export var ERRORS_KEY = 'errors';
export var EMAIL_ERROR = 'Ya hay un usuario con este email';
export var EXCELLENT = '¡Excelente!';
export var FILE_ERROR = 'Debe ser en formato pdf';
export var FILES_KEY = 'files';
export var FILTER_BY = 'Filtrar por: ';
export var FILTERS = 'Filtros';
export var OLD_FILES_KEY = 'oldFiles';
export var IMAGE_FILE_ERROR = 'Debe ser en formato jpeg o png';
export var FIFTH = 'fifth';
export var FIRST = 'first';
export var FIRST_CALL_KEY = 'time_first_call';
export var FIRST_CALL = 'Primera convocatoria';
export var FILE_EXTENSIONS = 'pdf';
export var IMAGE_EXTENSIONS = ['jpeg', 'png'];
export var INSURANCE_DESCRIPTION = 'Somos corredores de seguros de las principales compañías del mercado. Podremos brindar el mejor asesoramiento en cuanto a contratación de seguros, alcance y costos.';
export var FOURTH = 'fourth';
export var FROM = 'Desde';
export var FROM_THE = 'del';
export var FACEBOOK_URL = 'https://www.facebook.com/fascioliadministraciones/';
export var FASCIOLI_LOCATION = {
    lat: -34.89446895496644,
    lng: -56.1152909476333,
};
export var FASCIOLI_ASSISTS_KEY = 'fascioli_assists';
export var FASCIOLI_ASSISTS = 'Fascioli concurre';
export var FASCIOLI_ASSISTS_SECOND_CALL = 'Fascioli Administraciones concurrirá en segunda convocatoria';
export var FASCIOLI_DOES_NOT_ASSIST = 'Fascioli Administraciones NO concurre por tener asambleas ya establecidas';
export var FORGOT_PASSWORD = 'Olvidé mi contraseña ';
export var GARAGE = 'Garage';
export var GENERAL_SETTLEMENTS = 'Liquidaciones Generales';
export var GENERAL_SETTLEMENT = 'Liquidación General';
export var GO_TO_ACCOUNT = 'Ir a mi cuenta';
export var GOOGLE_MAPS_API = 'https://maps.googleapis.com/maps/api/staticmap?';
export var HABITANT = 'Habitante';
export var HABITANT_KEY = 'habitant';
export var HISTORY = 'Fascioli Administraciones comienza su actividad empresarial en 1985 en Malvín, iniciándose en el área de administración de edificios. Nuestra empresa hace acopio de una extensa y reconocida experiencia, y también de un legítimo prestigio y reconocimiento. Con una oficina, clara y firmemente establecida, actualmente estamos atendiendo con un servicio personalizado a decenas de edificios. \
  ¿Qué es un servicio personalizado?. La seriedad, la confiabilidad y la transparencia son, sin duda, atributos fundamentales para una administradora. Pero es también muy importante la capacidad de entender y satisfacer las demandas específicas de cada edificio en particular. Y estas no son frases hechas, son los aspectos tangibles de una gestión que nos ha consolidad y proyectado desde Malvín a otras zonas. \
  Además…Nuestro amplio conocimiento en la actividad hace que los copropietarios y la comisión administradora cuenten con el respaldo de una empresa referente en la actividad. \
  Trabajamos a diario con seriedad y responsabilidad, actualizándonos de forma permanente para mejorar nuestra atención. \
  Mantenemos un trato personal con cada edificio y un asesoramiento constante hacia cada copropietario, para que la administración de su propiedad sea mas fácil, y así, ofrecer la seguridad, tranquilidad y comodidad necesaria que usted merece. \
  Fascioli Administraciones es una empresa consolidada y el cimiento fundamental es la credibilidad que ha sabido ganarse a través de los años.';
export var HOLDER = 'Titular';
export var HOUR = 'Hora';
export var HOME = 'Inicio';
export var HOME_ADMIN_TEXT = 'admin';
export var HOME_TEXT = 'inicio';
export var HERO_TITLE = 'El valor de la confianza';
export var HERO_SECOND_TITLE = 'Comprá, Vendé o Alquilá CON CONFIANZA';
export var HERO_DESCRIPTION = 'Nuestro slogan "El valor de la confianza" no es una frase hecha y olvidada en el desarollo de nuestro trabajo, sino que nos esforzamos día a día por encontrar y ofrecer a nuestros clientes nuevos métodos que garanticen la transparencia de esta administración.';
export var HYBRID = 'Presencial y virtual';
export var IN_PROGRESS_KEY = 'in_progress';
export var IN_PROGRESS = 'En proceso';
export var INSTAGRAM_URL = 'https://www.instagram.com/fascioli_/';
export var INSURANCE = 'Seguros';
export var INFORMED_KEY = 'informed';
export var INFORMED = 'Informado';
export var ID = 'ID';
export var ID_KEY = 'id';
export var IDENTITY_CARD = 'C.I.';
export var IN_PERSON = 'Presencial';
export var IN_PERSON_PAYMENT = 'Pago Presencial';
export var IN_PERSON_PAYMENT_TEXT = 'Acuda a alguno de estos centros de pago con su número de cliente:';
export var INSTRUCTIONS_SENT = 'Instrucciones Enviadas';
export var INSTRUCTIONS_SENT_TEXT = 'Hemos enviado las instrucciones para recuperar tu contraseña a ';
export var INSTRUCTIONS_CREATE_ACCOUNT = 'Hemos enviado las instrucciones para crear tu contraseña a ';
export var INVALID_EMAIL = 'Email inválido';
export var INVALID_PHONE = 'Teléfono inválido';
export var INVALID_CLIENT = 'Cliente inválido';
export var INVALID_CLIENT_CODE = 'Código de cliente inválido';
export var INVALID_CREDENTIALS = 'Credenciales inválidas';
export var INVALID_PASSWORD = 'Contraseña menor a 8 caracteres';
export var INVALID_PASSWORD_CONFIRMATION = 'Las contraseñas deben coincidir';
export var INVALID_DATE = 'La fecha debe ser válida';
export var IMAGE_KEY = 'image';
export var IMPORT_DOCUMENT = 'Importar documento';
export var IMPORT_IMAGE = 'Importar imagen';
export var ISSUE_DATE = 'Fecha de Emisión';
export var JANUARY_FEBRUARY = 'Enero y Febrero';
export var LAST_CLIENTS = 'Últimos clientes';
export var LAST_NAME = 'Apellido';
export var LAST_NAME_KEY = 'last_name';
export var LAST_BUILDINGS_API = 'getLastBuildings';
export var LEGAL_DEPARTMENT = 'Departamento jurídico';
export var LEGAL_DEPARTMENT_DESCRIPTION = 'Contamos con un Deprtamento Jurídico altamente especializado en Propiedad Horizontal, capaz de dar solución a cada interrogante planteada.';
export var LINK_KEY = 'Link';
export var LINKEDIN_URL = 'https://www.linkedin.com/company/fascioli-administraciones-srl';
export var LOADING = 'Cargando';
export var LOCATION = 'Domicilio';
export var LOCATION_X2 = 'Ubicación';
export var LOCATION_KEY = 'location';
export var LOGIN = 'Acceso Clientes';
export var LOGIN_TEXT = 'ingresar';
export var LOGIN_LABEL = 'Ingresar';
export var M2 = 'm²';
export var MAINTENANCE = 'Mantenimiento';
export var MAINTENANCE_TEXT = 'mantenimiento';
export var MAINTENANCE_EMAIL = 'Email Mantenimiento';
export var MAINTENANCE_PHONE = 'Teléfonos de Mantenimiento';
export var MAIL = 'Correo';
export var MARCH_DECEMBER = 'Marzo a Diciembre';
export var MESSAGES = 'Mensajes';
export var MESSAGE_TEXT = 'mensaje';
export var MESSAGES_TEXT = 'mensajes';
export var MESSAGE_TO = 'Para:';
export var MESSAGE_TO_ALL = 'Le enviará el mensaje a todos los contactos del sistema';
export var MESSAGE_SUBJECT = 'Asunto:';
export var MESSAGE_TITLE = 'Título:';
export var MESSAGE_KEY = 'message';
export var MESSAGE_ID_KEY = 'messageId';
export var MESSAGE_DETAIL_API = 'getMessageDetail';
export var NEW_CLIENT_CODE_KEY = 'new_client_code';
export var NEIGHBORHOOD = 'Barrio';
export var MOBILE_KEY = 'mobile';
export var MONDAY_FRIDAY = 'Lunes a Viernes';
export var MODALITY = 'Modalidad:';
export var MODALITY_KEY = 'Modality';
export var MODALITY_TYPES = ['in_person', 'online', 'hybrid'];
export var MODIFY = 'Modificar';
export var MODIFY_EMAIL = 'Modificar correo';
export var MODIFIED_BY = 'Modificado por';
export var MODIFY_CONTACT_TEXT = 'modificar_contacto';
export var MODIFY_BUILDING_TEXT = 'modificar_edificio';
export var MODIFY_ERROR = 'Seleccione una torre';
export var MODIFY_PROVIDER_TEXT = 'modificar_proveedor';
export var MODIFY_PROVIDER = 'Modificar Proveedor';
export var MODIFY_PASSWORD = 'Modificar Contraseña';
export var MODIFY_EMAIL_PASSWORD = 'Modificar Email y Contraseña';
export var MORNING_HOURS = '8:00 - 12:15';
export var MODIFY_IMPORT_TEXT = 'Modificar Importe';
export var NAME = 'Nombre';
export var NAME_KEY = 'name';
export var NEW_BUILDING_TEXT = 'nuevo_edificio';
export var NEW_CLIENT_CODE = 'Nuevo código del cliente';
export var NEW_SUMMON = 'Nueva citación';
export var NEW_SUMMON_TEXT = 'nueva_citacion';
export var NEW_CONTACT_TEXT = 'nuevo_contacto';
export var NEW_PROVIDER_TEXT = 'nuevo_proveedor';
export var NEW_MESSAGE_TEXT = 'nuevo_mensaje';
export var NEW_MESSAGE = 'Nuevo mensaje';
export var NO_ASSOCIATED_CONTACTS = 'No hay contactos asociados.';
export var NONE = 'Ninguna';
export var SEE_MESSAGE = 'Ver mensaje';
export var SEE_CONTACTS = 'Ver contactos';
export var EDIT_MESSAGE = 'Editar mensaje';
export var NEW_PASSWORD = 'Clave nueva';
export var NEW_PASSWORD_TITLE = 'Nueva Contraseña';
export var NEW_PASSWORD_DESCRIPTION = 'Ingresa una nueva y segura contraseña para tu cuenta';
export var NEW_PASSWORD_TEXT = 'Nueva contraseña';
export var NEW_PASSWORD_KEY = 'newPassword';
export var NEW_RECEIPT_TEXT = 'nuevo_recibo';
export var NEW_RECEIPT = 'Nuevo recibo gastos comunes';
export var NEW_SERVICE = 'Nuevo servicio';
export var NEW_SERVICE_KEY = 'new_service';
export var NEXT_PAGE = 'Página siguiente';
export var NO = 'No';
export var NUMBER = 'Número';
export var ONLINE = 'Virtual';
export var ONLINE_COLLECTION = 'Cobranza en línea';
export var ONLINE_PAYMENT = 'Pago Online';
export var OUR_HISTORY = 'Nuestra Historia';
export var OUR_SERVICES = 'Nuestros servicios';
export var OUR_SERVICES_DESCRITPION = 'A continuación detallamos nuestras 4 áreas de actuación, en las cuales podemos brindar el asesoramiento que más se adecúe a sus necesidades';
export var OTHER_DOCUMENTS = 'Otros documentos';
export var OTHER_KEY = 'Otro';
export var ORDER_BY = 'Ordenar por: ';
export var ORDER_BY_TEXT_ONLY = 'Ordenar por';
export var ORDERS_OF_THE_DAY_KEY = 'orders_of_the_day';
export var ORDERS_OF_THE_DAY = 'Órdenes del día';
export var OWNER = 'Dueño';
export var OWNER_SIGNATURE = 'Firma Propietario';
export var OWNER_TEXT = 'Propietario';
export var OWNER_KEY = 'owner';
export var PASSWORD = 'Contraseña';
export var PAGE = 'Page';
export var PASSWORD_KEY = 'contraseña';
export var PASSWORD_KEY2 = 'password';
export var PASSWORD_CONFIRMATION_KEY = 'password_confirmation';
export var PAYMENT_DOESNT_CANCEL_DEBTS = 'EL PAGO DE ESTE RECIBO NO CANCELA DEUDAS ANTERIORES';
export var PARTNER_APARTMENT = 'Apartamentos Asociados';
export var PAYMENT_DATE_FROM = 'Fecha de pago - Desde';
export var PAYMENT_DATE_TO = 'Fecha de pago - Hasta';
export var PAYMENT_HISTORY = 'Histórico de pagos';
export var PAYMENT_HISTORY_API = 'getPaymentHistory';
export var PAYMENT_HISTORY_TEXT = 'historico_pagos';
export var PAYMENT_PROVIDER = 'Red de cobranza';
export var PAY_ONLINE = 'Pagar en línea';
export var PAY = 'Pagar';
export var PAYMENT_BY = 'Pago mediante';
export var PAYMENT_COMPLETED = 'Pago completado';
export var PAYMENT_FAIL = 'Pago No Realizado';
export var PAYMENT_STATUS = 'Pago';
export var PAYMENT_SUCCESS = 'Pago exitoso!';
export var PAYMENT_RECEIPT = 'Recibo:';
export var PAYMENT_AMOUNT = 'Monto:';
export var NETWORK_PAYMENTS = 'Pagos redes de cobranza';
export var PAYED = 'Pagado';
export var PAYED_AT = 'Fecha de pago';
export var PAYED_KEY = 'payed';
export var PAYMENT_NETWORK_KEY = 'paymentNetwork';
export var PAYMENT_METHOD = 'Forma de pago';
export var PDF_GENERATED = 'PDF generado correctamente';
export var PDF_KEY = 'pdf';
export var PENDING_KEY = 'pending';
export var PENDING = 'Pendiente';
export var PERSONAL_DETAILS = 'Datos personales';
export var PERIOD_DETAIL = 'Detalle del período';
export var PERMISSIONS = 'No tienes los permisos necesarios para ver este contenido.';
export var PHONE = 'Teléfono';
export var PHONE_KEY = 'phone';
export var PHONES_TOOLS_TEXT = 'telefonos_y_utiles';
export var PHONE_1 = '(+598) 2613 1183';
export var PHONE_2 = '';
export var PLACE = 'Lugar';
export var PLACE_KEY = 'place';
export var PLACE_SPECIFICATION_KEY = 'place_specification';
export var PRICE = 'Costo';
export var PRINCIPAL_CONTACT = 'Principal contacto';
export var PRINTABLE = 'Imprimible';
export var PROPERTY_IMAGE_ALT = 'Imagen de la propiedad';
export var PROPERTY_SHEET = 'ficha de propiedad';
export var PROPERTY_TYPE = 'Tipo de propiedad';
export var PROPRIETARY = 'Propietario';
export var PROPRIETARY_KEY = 'proprietary';
export var PROVIDERS = 'Proveedores';
export var PROVIDERS_TEXT = 'proveedores';
export var PROVIDER_TEXT = 'proveedor';
export var PROVIDERS_API = 'getProviders';
export var PROVIDER_API = 'getProvider';
export var PREVIOUS_PAGE = 'Página previa';
export var OWNER_OF_UNIT = 'propietario de la unidad';
export var PREVIEW = 'Previsualizar';
export var QUESTION_MARK = '?';
export var RESERVATIONS = 'Reservas';
export var RESERVATION_DATE_FROM = 'Fecha de reserva - Desde: ';
export var RESERVATION_DATE_TO = 'Fecha de reserva - Hasta: ';
export var RESERVATION_DETAILS = 'Detalle de Reserva';
export var RESERVATION_API = 'getReservations';
export var RESERVATION_API_EDIT = 'getReservationsForEdit';
export var RESERVATION_COST = 'Costo de la reserva';
export var RESERVATION_DATE = 'Día de la reserva';
export var RESERVATION_DATE_FOR = 'Reservado para el dia';
export var RESERVATION_MADE = 'Fecha realizada la reserva';
export var RESERVATION_SCHEDULE = 'Horario reservado';
export var RESERVATION_SUCCESS_MESSAGE = 'Reserva creada con éxito!';
export var RESERVATION_EDITED_SUCCESS_MESSAGE = 'Reserva editada con éxito!';
export var REAL_ESTATE = 'Inmobiliaria';
export var REAL_ESTATE_DEPARTMENT = 'Inmobiliaria';
export var REAL_ESTATE_DEPARTMENT_TEXT = 'inmobiliaria';
export var REAL_ESTATE_TEXT = 'inmobiliaria';
export var REAL_ESTATE_DESCRIPTION = 'El Deparamento de Inmobiliario fue re-lanzando en al año 2019, y hemos participado en algunas de las transacciones más importantes de los últimos tiempos. Nuestro equipo de ventas podrá brindarle el asesoramiento necesario en la materia.';
export var REPEAT_PASSWORD = 'Repetir contraseña';
export var RECEIPT_NUMBER_ERROR = 'Este número de recibo ya existe';
export var REQUIRED_FIELD = 'Campo requerido';
export var REQUIRED = '(obligatorio)';
export var RESET_PASSWORD = 'Recuperar contraseña';
export var RESET_PASSWORD_TEXT = 'Ingresá el email asociado a tu cuenta y nosotros te enviaremos un email con las instrucciones para reestablecer tu contraseña.';
export var RESET_PASSWORD_SUCCESSFUL = 'Tu nueva contraseña se ha creado con éxito';
export var RECEIPT = 'Recibo';
export var RECEIPT_MODIFIED_SUCCESSFUL = 'Recibo modificado con éxito';
export var RECEIPT_NUMBER = 'Recibo Nº';
export var RECEIPT_KEY = 'receipt';
export var RECEIPTS = 'Recibos';
export var RECEIPTS_KEY = 'receipts';
export var RECEIVER_KEY = 'receiver';
export var RECEPIT_NUMER = 'Número recibo (obligatorio)';
export var RELATIONSHIP = 'Relación';
export var RELATIONSHIP_KEY = 'relation';
export var RENT = 'Alquiler';
export var SALE = 'Venta';
export var SAVE = 'Guardar';
export var SERIOUSNESS_TEXT = 'Seriedad';
export var SETTLEMENT_KEY = 'settlement';
export var SCHEDULE = 'Horario';
export var SCHEDULES_NOT_AVAILABLE = '- No hay horarios disponibles -';
export var SEARCH = 'Buscar';
export var SECOND = 'second';
export var SECOND_CALL_KEY = 'time_second_call';
export var SECOND_CALL = 'Segunda convocatoria';
export var SELECT = '- Seleccionar -';
export var SELECT_PAYMENT_METHOD = 'Seleccionar forma de pago';
export var SENT = 'Enviado';
export var SEND_MESSAGE_ERROR = 'No se ha podido enviar el mensaje';
export var SIXTH = 'sixth';
export var SOLUTIONS = 'Tu edificio en la palma de la mano';
export var SOLUTIONS_APP_TITLE = '¿Ya te registraste para enterarte de las novedades?';
export var SOLUTIONS_APP_DESCRIPTION = 'Te ayudamos a optimizar tu tiempo y gestionar la actividad en tu edificio, desde cualquier dispositivo. \n \
  ¡No pierdas tiempo y registrate hoy mismo!';
export var SOLUTIONS_EASY = 'Un servicio transparente, fácil y cómodo';
export var SOLUTIONS_EASY_DESCRIPTION = "Accedé fácilmente a liquidaciones, actas, citaciones y realizá pagos de gastos comunes en línea desde nuestra aplicación. \n \
  También podes reservar los amenities de tu edificio de una manera mucho más fácil y rápida. \n\n \
  Descarga la App Fascioli hoy y transforma la manera en que gestionas tu edificio.";
export var SUBJECT_KEY = 'subject';
export var SUSPEND = 'Suspender';
export var SUSPENDED = 'Suspendido';
export var SUSPEND_CONFIRMATION = 'Estás seguro que deseas suspender la asamblea';
export var SPACE = 'Espacio';
export var DEMO = 'Ver demo';
export var SEE_MORE = 'Ver más';
export var SETTLEMENT = 'liquidación';
export var SETTELMENTS = 'Liquidaciones';
export var SETTELMENTS_TEXT = 'liquidaciones';
export var SETTLEMENTS_API = 'getSettlements';
export var SETTLEMENT_UPLOAD = 'Cargar liquidación';
export var SEND_INSTRUCTIONS = 'Enviar instrucciones';
export var SEND = 'Enviar';
export var SERVICES = 'Servicios';
export var SEE_OUR_SERVICES = 'Ver nuestros servicios';
export var SEE_PROVIDERS = 'Ver proveedores';
export var SERIOUSNESS_DESCRITPION = 'Brindamos un servicio serio y personalizado a cada cliente, teniendo la capacidad de entender y satisfacer los requerimientos específicos y particulares de cada situación.';
export var SHOULD_SEND_KEY = 'shouldSend';
export var SUBMIT_LOGIN_TEXT = 'Ingresar';
export var SUBJECT = 'Asunto';
export var SUCCESS_FILE_KEY = 'successFiles';
export var SUPERADMIN_KEY = 'superadmin';
export var SUMMON = 'Citación';
export var SUMMON_INVITE = 'Se convoca a los Sres. Copropietarios para el día, hora y lugar antes indicado para tratar el siguiente orden del día';
export var SUMMON_TYPE_KEY = 'summon_type';
export var SUMMONS_API = 'getSummons';
export var SUPERADMIN = 'Superadministrador';
export var SURNAME = 'Apellido';
export var SURNAME_KEY = 'surname';
export var STATE = 'Estado';
export var TENANT = 'Inquilino';
export var TENAT_KEY = 'tenant';
export var TENANT_TEXT = 'Quien suscribe (nombre y apellido)';
export var TERMS_CONDITIONS = 'términos y condiciones';
export var TEXT = 'text';
export var THIRD = 'third';
export var TIME_KEY = 'time';
export var TITLE_KEY = 'title';
export var TOWERS = 'Torres';
export var TOWER = 'Torre';
export var TOWER_KEY = 'tower';
export var TOWER_DATA_KEY = 'towerData';
export var TOTAL_AMOUNT = 'Total a Cobrar';
export var TOTAL_COST = 'Costo total';
export var TO_REPRESENT = 'a que me represente con voz y voto, con las mas amplias facultades para adoptar resolución';
export var TRANSPARENCY_TEXT = 'Transparencia';
export var TRANSPARENCY_DESCRITPION = 'Ofrecemos a nuestros clientes los métodos más eficientes para garantizar la transparencia de nuestra gestión, entregando la documentación original a la Comisión Administradora y cargando mensualmente copia de la documentación en nuestro sitio web.';
export var TRUST_DESCRITPION = 'La confianza es, quizás, el más importante de nuestros valores. Llevamos 37 años en funciones sin interrupciones en el mercado nacional, lo que ofrece respaldo y confianza a la hora de elegirnos.';
export var TYPE_CONTACT = 'Contact';
export var TYPE_ADMIN = 'Admin';
export var TYPE_TEXT = 'Tipo';
export var TYPE_KEY = 'type';
export var TYPE_OF_SERVICE = 'Tipo de servicio';
export var TYPE_OF_SERVICE_KEY = 'type_of_service';
export var TRUST_TEXT = 'Confianza';
export var UNTIL = 'Hasta';
export var UNIT_KEY = 'Unidad';
export var UNIT_ERROR = 'Debe ingresar un número';
export var UNPAID_COMMON_EXPENSES_API = 'getPendingCommonExpenses';
export var US = 'Nosotros';
export var USD = 'Dólares USD';
export var USEFUL_PHONES = 'Teléfonos útiles';
export var USER = 'usuario';
export var USERS_TEXT = 'usuarios';
export var USER_WITH_EMAIL = 'usuario con email: ';
export var US_TEXT = 'nosotros';
export var UPDATE_MESSAGE_ERROR = 'No se ha podido actualizar el mensaje';
export var UPLOAD_BILL = 'Subir facturas';
export var UPLOAD_MINUTES = 'Subir Acta';
export var UPLOAD_DATE = 'Fecha de subida';
export var UPLOAD = 'Subir';
export var UPLOAD_DOCUMENT = 'Subir documento';
export var UPLOAD_ASSEMBLY = 'Subir Acta';
export var UPLOAD_IMAGE = 'Subir Imagen';
export var UPLOAD_PDF = 'Subir PDF';
export var UYU = 'Pesos UYU';
export var VACATION_HOURS = '9:00 - 15:00';
export var VALIDATE_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export var VALIDATE_PHONE = /^$|^\d+$/;
export var VIEW = 'Ver';
export var YES = 'Si';
export var WELCOME = 'Bienvenido,';
export var XXS = 340;
export var XS = 385;
export var S = 600;
export var M = 785;
export var L = 991;
export var XL = 1024;
export var XXL = 1240;
export var XXXL = 1390;
export var XXXXL = 2250;
export var JANUARY = 'Enero';
export var FEBRUARY = 'Febrero';
export var MARCH = 'Marzo';
export var APRIL = 'Abril';
export var MAY = 'Mayo';
export var JUNE = 'Junio';
export var JULY = 'Julio';
export var AUGUST = 'Agosto';
export var SEPTEMBER = 'Septiembre';
export var OCTOBER = 'Octubre';
export var NOVEMBER = 'Noviembre';
export var DECEMBER = 'Diciembre';
export var MONTHS = [
    'Ene',
    'Feb',
    'Mar',
    'Abr',
    'May',
    'Jun',
    'Jul',
    'Ago',
    'Sep',
    'Oct',
    'Nov',
    'Dic',
];
export var REAL_ESTATE_SEARCH_ERRORS = {
    missingCity: 'Por favor, selecciona una ciudad',
    missingNeighborhood: 'Por favor, selecciona al menos un barrio',
    missingPropertyType: 'Por favor, selecciona al menos un tipo de propiedad',
};
export var REAL_ESTATE_SEARCH_PLACEHOLDERS = {
    city: 'Ingresá una ubicación',
    neighborhood: 'Ingresá un barrio',
    propertyType: '-Seleccionar-',
};
export var ROUTES = {
    contact: '/contacto',
    login: '/ingresar',
    reset_password: '/recuperar_contrasena',
    new_password: '/nueva_contrasena',
    about: '/nosotros',
    landing: '/',
    home: '/inicio',
    dashboard: '/dashboard',
    minutes: '/actas_asamblea',
    bill: '/factura',
    settlement: '/liquidaciones',
    settlement_upload: '/liquidaciones/cargar',
    summons: '/citacion_asambleas',
    new_summon: '/nueva_citacion',
    payment: '/historico_pagos',
    receipt: '/recibos_gastos_comunes',
    new_receipt: '/nuevo_recibo',
    edit_receipt: '/editar_recibo',
    account: '/cuenta',
    editAccount: '/editar_cuenta',
    changePassword: '/cambiar_contrasena',
    newClientCode: '/agregar_codigo',
    otherDocuments: '/otros_documentos',
    apartments: '/apartamentos',
    contacts: '/contactos',
    apartments_contacts: '/apartamentos/:id/contactos',
    buildings: '/edificios',
    new_contact: '/nuevo_contacto',
    messages: '/mensajes',
    new_message: '/nuevo_mensaje',
    edit_message: '/editar_mensaje',
    view_message: '/ver_mensaje',
    admins: '/administradores',
    providers: '/proveedores',
    new_provider: '/nuevo_proveedor',
    modify_provider: '/modificar_proveedor',
    modify_email: '/modificar_email',
    create_account: '/crear_cuenta',
    contact_detail: '/detalle_contacto',
    modify_contact: '/editar_contacto',
    create_admin: '/nuevo_administrador',
    modify_password: '/modificar_contrasena',
    online_payment: '/pago_en_linea',
    in_person_payment: '/pago_presencial',
    real_estate: '/inmobiliaria',
    real_estate_properties: '/inmobiliaria/propiedades',
    real_estate_detail: '/inmobiliaria/propiedades/:property_id',
    collection_manager: '/gestion_de_cobros',
    sistarbanc: '/sistarbanc',
    banred: '/banred',
    pdf_generator: '/pdf_generator',
    privacy: '/privacidad',
    reservations: '/reservas',
    create_reservation: '/crear_reserva',
    reservation_details: '/reserva/:id',
    edit_reservation: '/editar_reserva/:id',
};
export var DOCUMENT_ROUTES = [
    ROUTES.summons,
    ROUTES.receipt,
    ROUTES.settlement,
];
export var BankID = {
    BROU: '001',
    Itau: '113',
    Santander: '137',
    BBVA: '153',
    BanqueHeritage: '216',
    Scotiabank: '128',
    BANDES: '110',
    HSBC: '157',
};

var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React, { useEffect, useState, useReducer } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router';
import { AddPDF, MessageTrash, Img, exit } from 'src/assets/Images';
import { MESSAGE_SUBJECT, MESSAGE_TITLE, MESSAGE_TO, SAVE, SEND, CREATE_MESSAGE_ERROR, SEND_MESSAGE_ERROR, UPLOAD_IMAGE, UPLOAD_DOCUMENT, ROUTES, MESSAGE_ID_KEY, MESSAGE_KEY, DOCUMENT_TEXT, RECEIVER_KEY, SUBJECT_KEY, TITLE_KEY, BACK_ERROR_KEY, SUCCESS_FILE_KEY, SHOULD_SEND_KEY, FILES_KEY, OLD_FILES_KEY, UPDATE_MESSAGE_ERROR, BUILDINGS_KEY, ADD_FILE_ERROR, MESSAGE_TEXT, CREATE_MESSAGE_CONTACT_ERROR, DELETE_FILE_ERROR, getData, ALL_ENG, theme, } from 'src/Utils';
import { BeatLoader } from 'react-spinners';
import { AdminButton } from '../Common/AdminButton';
import MessageInformation from './MessageInformation';
import { MessageInput, FormContainer, BottomContainer, ButtonsContainer, Image, ImgContainer, ErrorMessage, Label, Div, Delete, } from './styles';
import { addFile, deleteFile, createMessage, deleteMessage, sendMessage, updateMessage, } from 'src/Views/MaintenancePages/Messages/services';
import Modal from '../Common/Modal';
import { init, reducer } from './Reducer';
import { DeleteModal } from '../Common';
import { isValidMessage } from 'src/Utils/validations';
var initialValues = {
    receiver: [],
    buildings: [],
    subject: '',
    title: '',
    message: '',
    files: [],
    oldFiles: [],
    messageId: null,
    shouldSend: false,
    backError: '',
    imageModal: false,
    documentModal: false,
    successFiles: false,
};
var allContacts = {
    label: 'Todos los contactos',
    value: 0,
};
var NewMessageForm = function (_a) {
    var _b = _a.isEdit, isEdit = _b === void 0 ? false : _b, _c = _a.isView, isView = _c === void 0 ? false : _c, viewMessage = _a.viewMessage, _d = _a.wasSent, wasSent = _d === void 0 ? false : _d;
    var history = useHistory();
    var _e = useReducer(reducer, initialValues, init), _f = _e[0], receiver = _f.receiver, buildings = _f.buildings, subject = _f.subject, title = _f.title, message = _f.message, files = _f.files, oldFiles = _f.oldFiles, messageId = _f.messageId, shouldSend = _f.shouldSend, backError = _f.backError, successFiles = _f.successFiles, dispatch = _e[1];
    var _g = useState(false), imageModal = _g[0], setImageModal = _g[1];
    var _h = useState(false), documentModal = _h[0], setDocumentModal = _h[1];
    var _j = useState(false), deleteDocumentModal = _j[0], setDeleteDocumentModal = _j[1];
    var _k = useState(), selectedFile = _k[0], setSelectedFile = _k[1];
    var _l = useState(''), formError = _l[0], setFormError = _l[1];
    var _m = useState(''), deleteFileError = _m[0], setDeleteFileError = _m[1];
    var _o = useState(false), filesFinished = _o[0], setFilesFinished = _o[1];
    var _p = useState([]), addresseesList = _p[0], setAddresseesList = _p[1];
    var _q = useState(false), checkAll = _q[0], setCheckAll = _q[1];
    useEffect(function () {
        if (buildings === null || buildings === void 0 ? void 0 : buildings.value) {
            getData("/buildings/" + buildings.value + "/contacts")
                .then(function (data) {
                var contactsList = data.contacts.map(function (_a) {
                    var first_name = _a.first_name, last_name = _a.last_name, id = _a.id;
                    return { label: first_name + ' ' + last_name, value: id };
                });
                contactsList.unshift(allContacts);
                setAddresseesList(contactsList);
            })
                .catch(function (error) {
            });
        }
    }, [buildings === null || buildings === void 0 ? void 0 : buildings.value]);
    useEffect(function () {
        if ((isEdit || isView) && viewMessage) {
            var receivers = viewMessage.receiver;
            dispatch({
                type: RECEIVER_KEY,
                payload: receivers,
            });
            if (!buildings || (buildings === null || buildings === void 0 ? void 0 : buildings.length) === 0) {
                dispatch({
                    type: BUILDINGS_KEY,
                    payload: viewMessage.building ?
                        [{ value: viewMessage.building, label: viewMessage === null || viewMessage === void 0 ? void 0 : viewMessage.building_name }]
                        : [],
                });
            }
            dispatch({ type: SUBJECT_KEY, payload: viewMessage.subject });
            dispatch({ type: TITLE_KEY, payload: viewMessage.title });
            dispatch({ type: MESSAGE_KEY, payload: viewMessage.body });
            dispatch({ type: OLD_FILES_KEY, payload: viewMessage.files });
            dispatch({ type: MESSAGE_ID_KEY, payload: viewMessage.id });
            dispatch({ type: ALL_ENG, payload: viewMessage.all });
            setCheckAll(viewMessage.all);
        }
    }, [addresseesList]);
    var addFiles = function (id) {
        files.length
            ? files.forEach(function (file) {
                return file.fileType &&
                    mutateAddFile({
                        id: id,
                        file: file,
                        fileType: file.fileType,
                    });
            })
            : dispatch({ type: SUCCESS_FILE_KEY, payload: true });
        setFilesFinished(true);
    };
    var _r = useState(false), deleteModal = _r[0], setDeleteModal = _r[1];
    var _s = useMutation(createMessage, {
        onSuccess: function (response) {
            dispatch({ type: BACK_ERROR_KEY, payload: '' });
            dispatch({ type: MESSAGE_ID_KEY, payload: response.data.id });
            addFiles(response.data.id);
        },
        onError: function (error) {
            dispatch({
                type: BACK_ERROR_KEY,
                payload: error.response.data.errors.contact
                    ? CREATE_MESSAGE_CONTACT_ERROR
                    : CREATE_MESSAGE_ERROR,
            });
        },
    }), mutateCreateMessage = _s[0], loadingCreateMessage = _s[1].isLoading;
    var mutateAddFile = useMutation(addFile, {
        onSuccess: function () {
            dispatch({ type: SUCCESS_FILE_KEY, payload: true });
        },
        onError: function () {
            dispatch({ type: SUCCESS_FILE_KEY, payload: false });
            dispatch({ type: BACK_ERROR_KEY, payload: ADD_FILE_ERROR });
        },
    })[0];
    var mutateDeleteFile = useMutation(deleteFile, {
        onSuccess: function () {
            dispatch({
                type: OLD_FILES_KEY,
                payload: oldFiles.filter(function (_a) {
                    var id = _a.id;
                    return selectedFile && id != selectedFile.id;
                }),
            });
            setDeleteDocumentModal(false);
        },
        onError: function () {
            selectedFile &&
                setDeleteFileError(DELETE_FILE_ERROR + " " + selectedFile.name);
        },
    })[0];
    var _t = useMutation(updateMessage, {
        onSuccess: function () {
            dispatch({ type: BACK_ERROR_KEY, payload: '' });
            addFiles(messageId);
        },
        onError: function () {
            dispatch({ type: BACK_ERROR_KEY, payload: UPDATE_MESSAGE_ERROR });
        },
    }), mutateUpdateMessage = _t[0], loadingUpdateMessage = _t[1].isLoading;
    var _u = useMutation(sendMessage, {
        onSuccess: function () { return history.push(ROUTES.messages); },
        onError: function () {
            dispatch({ type: SHOULD_SEND_KEY, payload: false });
            dispatch({ type: BACK_ERROR_KEY, payload: SEND_MESSAGE_ERROR });
        },
    }), mutateSendMessage = _u[0], loadingSendMessage = _u[1].isLoading;
    var mutateDeleteMessage = useMutation(deleteMessage, {
        onSuccess: function () { return history.push(ROUTES.messages); },
    })[0];
    var handleDelete = function (id) {
        if (id) {
            mutateDeleteMessage(id);
        }
        else {
            setDeleteModal(false);
            history.push(ROUTES.messages);
        }
    };
    var handleCreate = function () {
        var receivers = (receiver === null || receiver === void 0 ? void 0 : receiver.includes(allContacts)) ? ALL_ENG
            : receiver;
        if (isValidMessage({
            message: message,
            building: buildings,
            receiver: receivers,
            subject: subject,
            title: title,
            setFormError: setFormError,
            checkAll: checkAll,
        })) {
            if (isView) {
                mutateSendMessage(messageId);
                return;
            }
            if (!messageId) {
                mutateCreateMessage({
                    subject: subject,
                    body: message,
                    contacts: receivers === ALL_ENG ? receivers : (receivers === null || receivers === void 0 ? void 0 : receivers.length) > 0 ? receivers.map(function (item) { return item.value || item.first_name + ' ' + item.last_name; }) : [],
                    title: title,
                    building: (buildings === null || buildings === void 0 ? void 0 : buildings.value) || 0,
                    building_name: (buildings === null || buildings === void 0 ? void 0 : buildings.label) || '',
                    checkAll: checkAll,
                });
                return;
            }
            var building = (buildings === null || buildings === void 0 ? void 0 : buildings.value) ? buildings : (buildings === null || buildings === void 0 ? void 0 : buildings.length) > 0 ? buildings[0] : undefined;
            mutateUpdateMessage({
                id: messageId,
                title: title,
                subject: subject,
                body: message,
                contacts: receivers === ALL_ENG ? receivers : (receivers === null || receivers === void 0 ? void 0 : receivers.length) > 0 ? receivers.map(function (item) { return item.value; }) : [],
                building: (building === null || building === void 0 ? void 0 : building.value) || undefined,
                building_name: (building === null || building === void 0 ? void 0 : building.label) || '',
                checkAll: checkAll,
            });
        }
    };
    useEffect(function () {
        if (receiver.length > 1 && (receiver === null || receiver === void 0 ? void 0 : receiver.includes(allContacts))) {
            dispatch({
                type: RECEIVER_KEY,
                payload: [allContacts],
            });
        }
    }, [receiver]);
    useEffect(function () {
        if (!isView && messageId && filesFinished && successFiles) {
            if (shouldSend) {
                mutateSendMessage(messageId);
                setFilesFinished(false);
            }
            else
                history.push(ROUTES.messages);
        }
    }, [messageId, filesFinished, successFiles]);
    var createLoading = loadingCreateMessage || loadingUpdateMessage || loadingSendMessage;
    var renderInformation = function (label, input, dispatchKey) { return (React.createElement(MessageInformation, { label: label, input: input, setInput: function (string) { return dispatch({ type: dispatchKey, payload: string }); }, isView: isView, checkAll: checkAll, setCheckAll: setCheckAll })); };
    var renderFile = function (key, file, index) { return (React.createElement(Label, { key: key, className: "file" },
        !isView && (React.createElement(Delete, { src: exit, onClick: function () {
                if (index != undefined)
                    dispatch({
                        type: FILES_KEY,
                        payload: files.filter(function (file, i) { return i != index; }),
                    });
                else {
                    setSelectedFile(file);
                    setDeleteDocumentModal(true);
                }
            } })),
        file.name)); };
    return (React.createElement(React.Fragment, null,
        React.createElement(DeleteModal, { isOpen: deleteModal, setIsOpen: setDeleteModal, onDelete: function () { return handleDelete(messageId); }, itemLabel: MESSAGE_TEXT + " " + title }),
        React.createElement(Modal, { isOpen: documentModal, setIsOpen: setDocumentModal, title: UPLOAD_DOCUMENT, onSubmit: function (file) {
                file['fileType'] = 'pdf';
                dispatch({
                    type: FILES_KEY,
                    payload: __spreadArrays(files, [file]),
                });
            }, uploadDocument: true }),
        React.createElement(Modal, { isOpen: imageModal, setIsOpen: setImageModal, title: UPLOAD_IMAGE, onSubmit: function (file) {
                file['fileType'] = 'image';
                dispatch({
                    type: FILES_KEY,
                    payload: __spreadArrays(files, [file]),
                });
            }, uploadImage: true }),
        React.createElement(DeleteModal, { isOpen: deleteDocumentModal, setIsOpen: setDeleteDocumentModal, onDelete: function () {
                return selectedFile &&
                    !isView &&
                    mutateDeleteFile({
                        fileId: selectedFile.id,
                        fileType: selectedFile.type,
                        id: messageId,
                    });
            }, itemLabel: DOCUMENT_TEXT, error: deleteFileError, toDelete: "" + (selectedFile === null || selectedFile === void 0 ? void 0 : selectedFile.name) }),
        React.createElement(FormContainer, { className: createLoading ? 'disabled' : '' },
            createLoading && (React.createElement("div", { style: { position: 'absolute', top: '50%', left: '50%' } },
                React.createElement(BeatLoader, { size: "12px", color: theme.palette.black[100] }))),
            React.createElement(MessageInformation, { addressee: true, label: MESSAGE_TO, setAddressees: function (addressee) {
                    return dispatch({ type: RECEIVER_KEY, payload: addressee });
                }, setBuildings: function (buildings) {
                    return dispatch({ type: BUILDINGS_KEY, payload: buildings });
                }, addressees: receiver, buildings: buildings, isView: isView, checkAll: checkAll, setCheckAll: setCheckAll, addresseesList: receiver.includes(allContacts) ? [] : addresseesList }),
            renderInformation(MESSAGE_SUBJECT, subject, SUBJECT_KEY),
            renderInformation(MESSAGE_TITLE, title, TITLE_KEY),
            React.createElement(MessageInput, { value: message, onChange: function (e) {
                    return dispatch({ type: MESSAGE_KEY, payload: e.target.value });
                }, disabled: isView }),
            React.createElement(Div, null,
                oldFiles &&
                    oldFiles.map(function (file) { return renderFile(file.id, file); }),
                files &&
                    files.map(function (file, index) {
                        return renderFile(file.name + index, file, index);
                    }),
                !createLoading && (backError || formError) && (React.createElement(ErrorMessage, null, formError ? formError : backError)),
                React.createElement(BottomContainer, null,
                    !isView && (React.createElement(ButtonsContainer, null,
                        React.createElement(ImgContainer, null,
                            React.createElement(Image, { src: AddPDF, onClick: function () { return setDocumentModal(true); } }),
                            React.createElement(Image, { src: Img, onClick: function () { return setImageModal(true); } })),
                        React.createElement(ImgContainer, { className: "last" },
                            React.createElement(Image, { src: MessageTrash, onClick: function () { return setDeleteModal(true); } })))),
                    React.createElement(ButtonsContainer, { className: "gap" },
                        !isView && (React.createElement(AdminButton, { className: "white", onClick: handleCreate }, SAVE)),
                        !wasSent && (React.createElement(AdminButton, { onClick: function () {
                                dispatch({
                                    type: SHOULD_SEND_KEY,
                                    payload: true,
                                });
                                handleCreate();
                            } }, SEND))))))));
};
export default NewMessageForm;
